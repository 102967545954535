export const enviroment = {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    API_IMAGE_GALLERY_URL: process.env.REACT_APP_API_IMAGE_GALLERY_URL,
    DELIVERY_URL: process.env.REACT_APP_API_DELIVERY_URL,
    MAIN_API_URL: process.env.REACT_APP_MAIN_URL,
    BUSINESS_NAME: process.env.REACT_APP_BUSINESS_NAME,
    COMPANY_ID: process.env.REACT_APP_COMPANY_ID,
    STORE_ID: process.env.REACT_APP_STORE_ID,
    PRIMARY_COLOR: process.env.REACT_APP_PRIMARY_COLOR,
    SECONDARY_COLOR: process.env.REACT_APP_SECONDARY_COLOR,
    MENU_HOVER_COLOR: process.env.REACT_APP_MENU_HOVER_COLOR,
    ADD_BUTTON_COLOR: process.env.REACT_APP_ADD_BUTTON_COLOR,
    SEARCH_PLACEHOLDER: process.env.REACT_APP_SEARCH_PLACEHOLDER,
    PHONE_NUMBER: process.env.REACT_APP_PHONE_NUMBER,
    GST_NUMBER: process.env.REACT_APP_GST_NUMBER,
    EMAIL_ADDRESS: process.env.REACT_APP_EMAIL_ADDRESS,
    STORE_ADDRESS: process.env.REACT_APP_STORE_ADDRESS,
    FACEBOOK_LINK: process.env.REACT_APP_FACEBOOK_LINK,
    INSTAGRAM_LINK: process.env.REACT_APP_INSTAGRAM_LINK,
    TWITTER_LINK: process.env.REACT_APP_TWITTER_LINK,
    LINKEDIN_LINK: process.env.REACT_APP_LINKEDIN_LINK,
    YOUTUBE_LINK: process.env.REACT_APP_YOUTUBE_LINK,
    GOOGLE_APP: process.env.REACT_APP_GOOGLE_APP,
    APPLE_APP: process.env.REACT_APP_APPLE_APP,
}