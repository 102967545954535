export const reviews = [
    {
        id: 1,
        name: "Manav Verma",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a/ACg8ocJatwL8_irMwuyHBScTSXIWOA_NHugzs6gImgXAM5OmO4pq9Q=w36-h36-p-rp-mo-br100",
        rating: 5,
        review: `Tara Toys are built to last! My kids have been playing with their Tara Toy cars and trucks for years now, and they still look brand new. They're perfect for indoor or outdoor play, and they can withstand even the roughest toddler treatment. Plus, the variety of vehicles keeps them entertained for hours.`
    },
    {
        id: 2,
        name: "Nishant",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a-/ALV-UjXcEbhxDRL8VSSZc45WDOQ-vWvjRfIUDdKUahpo7QOGtWZ6455Y=w36-h36-p-rp-mo-br100",
        rating: 5,
        review: `Tara Toy is my go-to for preschool supplies! Their selection of educational toys is fantastic, with something for every age group and learning style. The sensory bins are a huge hit with my students, and the customer service is always top-notch.`
    },
    {
        id: 3,
        name: "avinash Jha",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a-/ALV-UjVCGA_5Ye68OglcKE_ClzE6i7ajS_93JV_IGLPlsWrjJQelpRwu=w36-h36-p-rp-mo-ba4-br100",
        rating: 5,
        review: `Tara Toy's soft play equipment is a lifesaver at my daycare! It's a safe and fun way for the little ones to burn energy. The shapes are easy to clean, and the variety keeps them entertained. Just wish there were a few more options for older toddlers.`
    }
];

export const specials = {
    "topBrands": [
        {
            "name": "Hot Wheels",
            "url": "/store-product/brand/2300"
        },
        {
            "name": "Nerf",
            "url": "/store-product/brand/2497"
        },
        {
            "name": "Barbie",
            "url": "/store-product/brand/barbie"
        },
        {
            "name": "Lego",
            "url": "/store-product/brand/lego"
        },
        {
            "name": "Play Shifu",
            "url": "/store-product/brand/playshifu"
        },
        {
            "name": "Smartivity",
            "url": "/store-product/brand/smartivity"
        },
        {
            "name": "Miko",
            "url": "/store-product/brand/miko"
        },
        {
            "name": "Funskool",
            "url": "/store-product/brand/funskool"
        },
        {
            "name": "Sameo",
            "url": "/store-product/brand/sameo"
        },
        {
            "name": "Hasbro",
            "url": "/store-product/brand/hasbro"
        },
        {
            "name": "Giggles",
            "url": "/store-product/brand/giggles"
        },
        {
            "name": "Majorette",
            "url": "/store-product/brand/majorette"
        },
        {
            "name": "Jada Toys",
            "url": "/store-product/brand/jada"
        },
    ],

    "mainHeading": `${process.env.REACT_APP_BUSINESS_NAME}'s Toy Store - India's most loved place to buy toys for children!`,

    "mainHeadingDescription": `${process.env.REACT_APP_BUSINESS_NAME}'s Toy Store is renowned as India's favorite destination for purchasing children's toys. It offers a wide variety of high-quality, engaging, and fun toys that cater to kids of all ages. Parents and children alike love the store for its excellent customer service, diverse selection, and commitment to bringing joy to playtime.`,

    "subHeading": `Why ${process.env.REACT_APP_BUSINESS_NAME}'s Toy Store?`,

    "subHeadingImpPoints": [
        {
            "heading": "Quality Assurance:",
            "description": "Our toys undergo rigorous testing to ensure they are safe, durable, and meet the highest quality standards."
        },
        {
            "heading": "Expertise:",
            "description": "Our team consists of toy enthusiasts and professionals who bring years of experience to provide the best play experiences for your children."
        },
        {
            "heading": "Innovative Solutions:",
            "description": "From interactive toys to educational games, we offer a wide selection of products designed to keep your kids engaged, learning, and having fun."
        },
        {
            "heading": "Customer Satisfaction:",
            "description": "Your satisfaction is our priority. We strive to provide exceptional service and support, ensuring that every shopping experience is enjoyable for both you and your children."
        },
        {
            "heading": "Convenience:",
            "description": "Shop online or visit our store by searching for a toy store near me. We offer the best toy solutions for your convenience."
        },

    ]
}

export const aboutUs = {
    "heading": `About ${process.env.REACT_APP_BUSINESS_NAME} &ndash; Your Premium Toy Destination`,

    "description": `${process.env.REACT_APP_BUSINESS_NAME} is where the joy of childhood comes alive. We&#39;re a premium toy store dedicated to bringing the world&#39;s best playthings to your doorstep. Whether you&#39;re searching for a classic wooden puzzle, the latest tech-powered robot, or a beloved character from your favorite story, you&#39;ll find it at ${process.env.REACT_APP_BUSINESS_NAME}.`,

    "keyDifference": `The ${process.env.REACT_APP_BUSINESS_NAME} Difference`,

    "keyDifferencePoints": [
        {
            "heading": "Curated Collection:",
            "description": "&nbsp;We carefully handpick toys from around the world and India, ensuring a mix of both internationally renowned brands and exceptional local craftsmanship."
        },
        {
            "heading": "Premium Experience:",
            "description": "&nbsp;From the moment you explore our website to the day your little one unwraps their new treasure, we strive to provide a delightful and seamless shopping experience."
        },
        {
            "heading": "Passion for Play:",
            "description": `&nbsp;Our founder, Varun Behl, is a toy enthusiast at heart. His dynamic vision drives ${process.env.REACT_APP_BUSINESS_NAME}&#39;s mission to make us the preferred destination for all things toys in India.`
        }
    ],

    "otherInfo": `<p>Discover the Wonders of Play</p><p> Come explore the endless possibilities of imagination and creativity at ${process.env.REACT_APP_BUSINESS_NAME}.</p><p> Let us help you find the perfect toy that will spark joy and wonder in your child&#39;s life!</p>`
}